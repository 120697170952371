.App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    font-feature-settings: "salt" on, "ss04" on, "calt" off;
    padding: 20px 0 0 0;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .tools {
    margin-bottom: 16px;
  }

  button {
    font-size: 16px;
    background: rgb(240, 240, 240);
    border: 0;
    margin-right: 10px;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
  }

  .hot-keys {
    display: flex;
    text-align: left;
    flex-direction: column;
  }

  h5 {
    margin-bottom: 8px;
  }

  .img-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  #contextMenu span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .menu {
    box-shadow: -1px 4px 5px 3px rgb(0 0 0 / 1%);
    border: 1px solid #aaaaaa2e;
  }
